

import React, { useEffect, useState } from 'react';
import Admonition from 'react-admonitions';

import { navigate, withPrefix } from 'gatsby';
import Loading from 'components/Loading';
import Button from 'elements/Button';
import Layout from 'layout';
import { auth, signInWithGoogle } from 'utils/firebase';

function ErrorModal({ isError, errorMsg }) {
  return isError && (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px',
        fontSize: '20px',
      }}
    >
      <Admonition type="caution" iconType="⚠️" title="Oops... Something went wrong">
        <p style={{ fontSize: '15px' }}>{errorMsg}</p>
      </Admonition>
    </div>
  );
}

export default function Login({ location }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  auth.onAuthStateChanged(async user => {
    if (user !== null) {
      setIsLoggedIn(true);
    }
    setAuthLoading(false);
  });

  const handleLogin = () => {
    signInWithGoogle().then(() => {
      setIsLoggedIn(true);
    }).catch(err => {
      setErrorMsg(err.message);
      setIsError(true);
    });

    setAuthLoading(false);
  };

  useEffect(() => {
    const destinationPath = location.state?.origin || '/';

    if (isLoggedIn) {
      navigate(destinationPath);
    }
  }, [isLoggedIn]);

  if (authLoading) {
    return <Loading />;
  }

  return (
    <Layout title="Login" location={location}>
      <ErrorModal isError={isError} errorMsg={errorMsg} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '65vh',
          fontSize: '20px',
        }}
      >
        <div>
          {/* <img
            src={LOGO_IMG}
            alt="logo"
            style={{
              maxHeight: '80px',
              marginBottom: '30px'
            }}
          /> */}

          <div style={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Button
              borderRadius='28px'
              fontSize='16px'
              lineHeight={1}
              minWidth='100px'
              paddingX='20px'
              paddingY='20px'
              onClick={() => handleLogin()}
            >
              <span>
                <img src={withPrefix('images/icons/google.png')} style={{ maxWidth: '20px', marginRight: '5px' }} />
              </span>
              Login With Google
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}